<template>
  <app-modal
    class="job-posting-example-modal"
    modal-size="box-in-the-middle"
    :backdrop-click-close="true"
    v-on="$listeners"
  >
    <main class="job-posting-example-modal__body">
      <img
        class="job-posting-example-modal__image"
        data-test="image"
        :src="imageComponent"
      >
    </main>
  </app-modal>
</template>

<script>
import imgReveloVagas from '@/assets/images/revelo-vagas.png'
import imgGoogleJobs from '@/assets/images/google-jobs.png'

export default {
  name: 'JobPostingExampleModal',
  props: {
    image: {
      type: String,
      required: true,
      validator: function (value) {
        return ['google-jobs', 'revelo-vagas'].includes(value)
      }
    }
  },
  computed: {
    imageComponent () {
      if (this.image === 'google-jobs') return imgGoogleJobs
      else return imgReveloVagas
    }
  }
}
</script>

<style lang="scss">
.job-posting-example-modal {
  &__image {
    max-width: 100%;
  }
}
</style>
