<template>
  <main class="job-posting">
    <app-grid>
      <app-grid-col :md="10" :md-start="2" class="job-posting__col">
        <section class="job-posting__container">
          <section class="job-posting__container-columns">
            <app-grid-col
              class="job-posting__description"
              :md="10"
              :md-start="1"
            >
              <router-link
                class="job-posting__back-link"
                data-test="back"
                :to="{ name: 'WorkflowBoard', params: { positionId } }"
              >
                <icn-prev
                  class="job-posting__back-link-arrow"
                  height="18"
                  view-box="0 0 32 32"
                  width="18"
                />
                <span class="job-posting__back-link-text">
                  {{ $t(`${langPath}.goBack`) }}
                </span>
              </router-link>
              <h1
                class="job-posting__title"
                data-test="title"
              >
                {{ title }}
              </h1>

              <i18n
                :path="`${langPath}.subtitle`"
                class="job-posting__subtitle"
                data-test="subtitle"
                tag="p"
              >
                <a
                  class="job-posting__subtitle-link"
                  data-test="example-link-1"
                  href="javascript:void(0)"
                  @click="handleShowExample('revelo-vagas')"
                >
                  {{ $t(`${langPath}.here`) }}
                </a>
              </i18n>

              <section class="job-posting__input-wrapper">
                <app-rich-text-input
                  v-model="description"
                  v-validate="'required'"
                  :label="$t(`${langPath}.description`)"
                  custom-class="job-posting__input"
                  data-test="input"
                  name="description"
                />
              </section>
            </app-grid-col>
            <app-grid-col :md="10" :md-start="1">
              <job-posting-actions
                v-if="currentPosition"
                data-test="actions"
                :allow-skip="allowSkip"
                :is-submitting="isSubmitting"
                :is-toggling="isToggling"
                :position="currentPosition"
                :show-buttons="showButtons"
                @showExample="handleShowExample('google-jobs')"
                @copy="handleCopy"
                @skip="redirectToWorkflow"
                @submit="handleSubmit"
                @toggle="handleToggle"
              />
            </app-grid-col>
          </section>
        </section>
      </app-grid-col>
    </app-grid>
    <job-posting-example-modal
      v-if="exampleToShow"
      data-test="modal"
      :image="exampleToShow"
      @close="handleCloseExample"
    />
  </main>
</template>

<script>
import { IcnPrev, TheNotificationCardList } from '@revelotech/everest'
import { createNamespacedHelpers } from 'vuex'
import { POSITIONS } from '@/store/namespaces'
import { copyToClipboard } from '@/helpers/clipboard'
import JobPostingActions from './components/JobPostingActions'
import JobPostingExampleModal from './components/JobPostingExampleModal'

const positionsHelper = createNamespacedHelpers(POSITIONS)

export default {
  components: { IcnPrev, JobPostingActions, JobPostingExampleModal },
  data () {
    return {
      description: '',
      isSubmitting: false,
      isToggling: false,
      langPath: __langpath,
      positionLink: null,
      exampleToShow: null
    }
  },
  computed: {
    ...positionsHelper.mapState(['positions']),
    allowSkip () {
      return this.$route.query?.allowSkip === 'true'
    },
    currentPosition () {
      return this.positions.find((position) => {
        return position.id === this.positionId
      })
    },
    jobPosting () {
      return this.currentPosition?.jobPosting
    },
    positionId () {
      return this.$route.params.positionId.toString()
    },
    showButtons () {
      if (!this.jobPosting) return true

      return this.description !== this.jobPosting.description
    },
    title () {
      return this.$t(
        `${this.langPath}.title`,
        { positionTitle: this.currentPosition?.title }
      )
    }
  },
  async created () {
    this.description = this.$t(`${this.langPath}.placeholder`)
    await this.getPosition({ positionId: this.positionId })

    if (this.jobPosting) {
      this.description = this.jobPosting.description
    }
  },
  methods: {
    ...positionsHelper.mapActions(['createJobPosting', 'getPosition']),
    async handleSubmit () {
      if (!(await this.$validator.validate())) return

      try {
        this.isSubmitting = true

        await this.createJobPosting({
          positionId: this.positionId,
          description: this.description
        })

        this.positionLink = this.currentPosition.sitePositionLink
      } catch (error) {
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.errorPublishing`),
          modifier: 'error'
        })
      } finally {
        this.isSubmitting = false
      }
    },
    async handleToggle () {
      try {
        this.isToggling = true
        await this.createJobPosting({
          positionId: this.positionId,
          description: this.jobPosting.description,
          active: !this.jobPosting.active
        })
      } catch (error) {
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.errorPublishing`),
          modifier: 'error'
        })
      } finally {
        this.isToggling = false
      }
    },
    async handleCopy () {
      if (copyToClipboard(this.currentPosition.sitePositionLink)) {
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.successCopying`),
          modifier: 'success'
        })
      } else {
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.errorCopying`),
          modifier: 'error'
        })
      }
    },
    handleShowExample (input) {
      this.exampleToShow = input
    },
    handleCloseExample () {
      this.exampleToShow = null
    },
    redirectToWorkflow () {
      this.$router.push({
        name: 'WorkflowBoard',
        params: { positionId: this.positionId }
      })
    }
  }
}
</script>

<style lang='scss'>
.job-posting {
  background-color: $white;
  padding: ($app-header-height+$base*3) 1% 0;

  @include breakpoint(md) {
    padding-top: $app-header-height + $base*12;
  }

  &__back-link {
    @extend %body-text1;

    align-items: center;
    display: flex;
    padding: $base*4 0;

    &-text {
      color: $blue;
      margin-left: $base*2;

      &:hover {
        color: $blue-dark;
      }
    }
  }

  &__container-columns {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-left: 2*$base;
    margin-right: 2*$base;

    @include breakpoint(md) {
      grid-template-columns: repeat(10, 1fr);
      margin-left: 6*$base;
      margin-right: 6*$base;
    }
  }

  &__container {
    background: $white;
    padding: $base*3 $base*3 $base*12 $base*3;

    @include breakpoint(md) {
      padding: 0 0 $base*20 0;
    }
  }

  &__input-wrapper {
    @include margin(top, 6);
  }

  &__input {
    min-height: $base*75;
  }

  &__title {
    @include margin(top, 6);

    @extend %h4;
  }

  &__subtitle {
    line-height: 2;
    margin-top: $base * 4;

    &-link {
      color: $blue-light;
      display: inline-block;
    }
  }

  &__actions {
    @include margin(top, 6);

    display: flex;
    justify-content: flex-end;

    &__submit {
      margin-left: 3*$base;
    }
  }
}
</style>
