<template>
  <section class="job-posting-actions">
    <section
      v-if="showButtons"
      class="job-posting-actions__buttons-area"
      data-test="buttons-area"
    >
      <section
        v-if="!position.jobPosting"
        class="job-posting-actions__message-box"
        data-test="google-jobs-message"
      >
        <icon-base
          class="job-posting-actions__message-box-icon"
          height="32"
          icon-name="google"
          view-box="0 0 24 24"
          width="32"
        />
        <i18n
          class="job-posting-actions__message-box-description"
          tag="p"
          :path="`${langPath}.googleJobsMessage`"
        >
          <a
            class="job-posting-actions__message-box-link"
            href="javascript:void(0)"
            @click="$emit('showExample')"
          >
            {{ $t(`${langPath}.anExample`) }}
          </a>
        </i18n>
      </section>
      <section class="buttons">
        <ev-button
          v-if="allowSkip"
          data-ga-id="clicked_on_skip_job_posting_creation"
          data-test="skip-button"
          color="blue"
          variant="secondary"
          @click="$emit('skip')"
        >
          {{ $t(`${langPath}.skip`) }}
        </ev-button>

        <ev-button
          class="job-posting-actions__submit-button"
          data-ga-id="clicked_on_create_job_posting"
          data-test="submit-button"
          :loading="isSubmitting"
          @click="$emit('submit')"
        >
          {{ primaryButtonTitle }}
        </ev-button>
      </section>
    </section>
    <section
      v-if="position.jobPosting"
      class="job-posting-actions__secondary-actions"
      :class="{ 'job-posting-actions__secondary-actions--topless': !showButtons }"
      data-test="secondary-actions"
    >
      <section class="job-posting-actions__switch" data-test="switch-container">
        <ev-switch
          class="job-posting-actions__input"
          data-test="switch"
          :loading="isToggling"
          name="status"
          :value="jobPostingActive"
          @click="handleChange"
        />
        <section class="job-posting-actions__switch-text">
          <label
            class="job-posting-actions__switch-label"
            for="status"
            data-test="switch-label"
          >
            {{ switchLabel }}
          </label>
          <app-assistive-text
            :assistive-text="switchAssistiveText"
            class="job-posting-actions__switch-assistive-text"
            data-test="switch-assistive-text"
          />
        </section>
      </section>
      <section
        v-if="jobPostingActive"
        class="job-posting-actions__link-box"
        data-test="link-box"
      >
        <app-text-input
          class="job-posting-actions__link"
          data-test="link"
          :label="$t(`${langPath}.linkLabel`)"
          name="link"
          :disabled="true"
          :value="jobPostingLink"
        />
        <ev-button
          class="job-posting-actions__link-button"
          data-test="copy-button"
          variant="secondary"
          color="blue"
          @click="$emit('copy')"
        >
          {{ $t(`${langPath}.copy`) }}
        </ev-button>
      </section>
    </section>
  </section>
</template>

<script>
import EvSwitch from '@revelotech/everestV2/EvSwitch'
import EvButton from '@revelotech/everestV2/EvButton'
import IconBase from '@/components/IconBase'

export default {
  components: {
    EvSwitch,
    EvButton,
    IconBase
  },
  props: {
    allowSkip: {
      type: Boolean,
      default: false
    },
    isSubmitting: {
      type: Boolean,
      default: false
    },
    isToggling: {
      type: Boolean,
      default: false
    },
    position: {
      type: Object,
      required: true
    },
    showButtons: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    jobPostingActive () {
      return this.position.jobPosting.active
    },
    jobPostingLink () {
      return this.position.sitePositionLink
    },
    primaryButtonTitle () {
      const action = this.position.jobPosting ? 'save' : 'create'
      return this.$t(`${this.langPath}.${action}`)
    },
    switchAssistiveText () {
      if (this.jobPostingActive) {
        return this.$t(`${this.langPath}.acceptingApplicationsLong`)
      } else {
        return this.$t(`${this.langPath}.notAcceptingApplicationsLong`)
      }
    },
    switchLabel () {
      if (this.jobPostingActive) {
        return this.$t(`${this.langPath}.acceptingApplicationsShort`)
      } else {
        return this.$t(`${this.langPath}.notAcceptingApplicationsShort`)
      }
    }
  },
  methods: {
    handleChange (e) {
      e.preventDefault()
      this.$emit('toggle')
    }
  }
}
</script>

<style lang="scss">
.job-posting-actions {
  display: flex;
  flex-direction: column;

  &__message-box {
    background-color: $gray5;
    border-radius: $base;
    display: flex;
    font-size: $base*3.5;
    line-height: 1.5;
    padding: $base*2;

    @include breakpoint(md) {
      max-width: 70%;
    }

    &-description {
      margin-left: $base*3.5;
    }

    &-link {
      color: $blue-light;
      margin-left: 0;
    }

    &-icon {
      align-self: center;
      flex-shrink: 0;
      margin-left: $base*1.5;

      path {
        fill: $gray40;
      }
    }
  }

  &__buttons-area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: $base*8;

    @include breakpoint(md) {
      flex-direction: row;
    }

    .buttons {
      display: flex;
      margin-left: auto;
      margin-top: $base*8;

      @include breakpoint(md) {
        margin-top: initial;
      }

      * {
        margin-left: $base*3;
      }
    }
  }

  &__input {
    min-width: $base*10;

    input {
      cursor: pointer;
    }
  }

  &__secondary-actions {
    border-top: 1px solid $gray20;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: $base*8;
    padding-top: $base*8;

    &--topless {
      border-top: 0;
      margin-top: 0;
    }

    @include breakpoint(md) {
      flex-direction: row;
    }
  }

  &__switch {
    display: flex;
    flex-direction: row;
  }

  &__switch-assistive-text {
    padding-left: 0;
  }

  &__switch-label {
    font-size: $base*3.5;
    font-weight: bold;
  }

  &__switch-text {
    margin-left: $base*3;

    .assistive-text {
      height: initial;
    }
  }

  &__link .text-input {
    padding-right: $base*3;
  }

  &__link-box {
    display: flex;
    flex-direction: column;
    margin-top: $base*8;

    @include breakpoint(md) {
      flex-direction: row;
      margin-top: initial;
    }
  }

  &__link-button {
    align-self: flex-end;
    color: $blue-dark;
    margin-left: $base*3;
    margin-top: $base*3;
    min-width: $base*30;
    width: 100%;

    @include breakpoint(md) {
      margin-top: initial;
      width: initial;
    }
  }
}
</style>
